import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import {
  Dashboard as DashboardIcon,
  ReportProblem as ReportProblemIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  MarkUnreadChatAlt as MarkUnreadChatAltIcon,
  Settings as SettingsIcon,
  Business as BusinessIcon,
  Inventory as InventoryIcon,
  AddBusiness,
  SystemUpdateAltOutlined,
  MarkUnreadChatAltOutlined,
  Inventory2Outlined,
  BusinessCenterTwoTone,
} from "@mui/icons-material";
import { themeBackgroundPrimaryColor } from "../utilities/colorTheme";

const themeColor = "#40434E";
const hoverColor = "#3a3c46";
const hoverColorActive = "#2d2f37";
const textColor = "#c6c7ca";

const SidebarContainer = styled(Drawer)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  width: 240,
  padding: "10px",
  "& .MuiDrawer-paper": {
    width: 240,
    boxSizing: "border-box",
    background: themeBackgroundPrimaryColor,
  },
});

const SidebarContent = styled("div")({
  marginTop: "70px",
  padding: "10px",
  flex: 1,
});

const StyledButton = styled(Button)(({ isdataactive }) => ({
  width: "100%",
  marginBottom: "4px",
  color: textColor,
  background: isdataactive ? hoverColorActive : "transparent",
  justifyContent: "flex-start", // Align content to the start
  paddingLeft: "16px", // Add padding for spacing on the left side
  "&:hover": {
    background: hoverColor,
    color: "white",
  },
}));

const StyledListItemIcon = styled("div")({
  color: "white",
  minWidth: "40px", // Ensure consistent spacing with the text
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const StyledListItemText = styled("div")({
  color: "white",
  marginLeft: "10px", // Spacing between icon and text
  textAlign: "left", // Ensure the text is aligned to the left
});

const Divider = styled("hr")({
  backgroundColor: "white",
  opacity: 0.1,
  margin: "0px 0",
});

const AppSidebar = ({ isMobile, setDrawerOpen }) => {
  const userType = useSelector((state) => state.userData.data.userType);
  const userRole = useSelector((state) => state.userData.data.role);
  const sessionUser = useSelector((state) => state.userData.data);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItems, setActiveItems] = useState({});

  const isItemActive = (path) => location.pathname === path;

  useEffect(() => {
    setActiveItems({
      "/": isItemActive("/"),
      "/Support": isItemActive("/Support"),
      "/enquiry-management": isItemActive("/enquiry-management"),
      "/tickets": isItemActive("/tickets"),
      "/User-Management": isItemActive("/User-Management"),
      "/Company-Management": isItemActive("/Company-Management"),
      "/Settings": isItemActive("/Settings"),
      "/Asset-Management": isItemActive("/Asset-Management"),
      "/franchise": isItemActive("/franchise"),
      "/export-assets-management": isItemActive("/export-assets-management"),
      "/it-amc-Management": isItemActive("/it-amc-Management"),
    });
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile && setDrawerOpen) setDrawerOpen(false);
  };

  const menuItems = [
    { path: "/", label: "Dashboard", icon: <DashboardIcon /> },
    {
      path: "/Support",
      label: "Support",
      icon: <MarkUnreadChatAltIcon />,
      roles: ["superAdmin", "coreAdmin", "supportAgent"],
    },
    {
      path: "/enquiry-management",
      label: "Enquiry Support",
      icon: <MarkUnreadChatAltOutlined />,
      roles: ["superAdmin", "coreAdmin", "supportAgent"],
    },
    { path: "/tickets", label: "Tickets", icon: <ReportProblemIcon /> },
    {
      path: "/User-Management",
      label: "Users",
      icon: <SupervisedUserCircleIcon />,
      roles: [
        "superAdmin",
        "customerAdmin",
        "technicalAdmin",
        "technician",
        "coreAdmin",
        "supportAgent",
      ],
    },
    {
      path: "/Company-Management",
      label: "Companies",
      icon: <BusinessIcon />,
      roles: [
        "superAdmin",
        "technicalAdmin",
        "technician",
        "coreAdmin",
        "supportAgent",
      ],
    },
    {
      path: "/Renewals-Management",
      label: "Renawals",
      icon: <BusinessCenterTwoTone />,
      roles: [
        "superAdmin",
        "technicalAdmin",
        "technician",
        "coreAdmin",
        "supportAgent",
      ],
    },
    {
      path: "/Asset-Management",
      label: "Assets",
      icon: <InventoryIcon />,
      userType: "Admin",
    },
    {
      path: "/it-amc-Management",
      label: "IT AMC",
      icon: <Inventory2Outlined />,
      userType: "Admin",
    },
    {
      path: "/franchise",
      label: "Franchise",
      icon: <AddBusiness />,
      roles: ["coreAdmin"],
    },
    {
      path: "/Settings",
      label: "Settings",
      icon: <SettingsIcon />,
      roles: ["superAdmin", "coreAdmin"],
    },
    {
      path: "/export-assets-management",
      label: "Export Assets",
      icon: <SystemUpdateAltOutlined />,
      permission: sessionUser.isEditExportAsset,
    },
  ];

  const checkAccess = (item) => {
    if (item.roles && !item.roles.includes(userRole)) return false;
    if (item.userType && item.userType !== userType) return false;
    if (item.permission !== undefined && !item.permission) return false;
    return true;
  };

  return (
    <SidebarContainer variant="permanent" anchor="left">
      <SidebarContent>
        <List>
          {menuItems.map(
            (item, index) =>
              checkAccess(item) && (
                <React.Fragment key={item.path}>
                  <StyledButton
                    component="li"
                    isdataactive={activeItems[item.path] ? "true" : undefined}
                    onClick={() => handleNavigation(item.path)}
                  >
                    <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                    <StyledListItemText>{item.label}</StyledListItemText>
                  </StyledButton>
                  {index < menuItems.length - 1 && <Divider />}
                </React.Fragment>
              )
          )}
        </List>
      </SidebarContent>
    </SidebarContainer>
  );
};

export default AppSidebar;
