import React, { useCallback, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme as muiUseTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import BlockIcon from '@mui/icons-material/Block';
import enLocale from "date-fns/locale/en-GB";
import { ToastContainer } from 'react-toastify';
import theme from './theme';
import { updateCurrentFranchise } from './app/Franchise/FranchiseSlice';
import { superAdminCompanyExist } from './app/Ticket/Service';
import PrivateRoutes from './PrivateRoutes'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/Loading';

// Lazy loading components
// const PrivateRoutes = lazy(() => import("./PrivateRoutes"));
const CustomerLogin = lazy(() => import("./pages/Login"));
const AdminChatComponent = lazy(() => import("./pages/Support/AdminChatSupport/AdminChatSupport"));
const UserManagement = lazy(() => import("./pages/UserMangaement/UserManagement"));
const TicketsManagement = lazy(() => import("./pages/TicketManagement/TicketsPage"));
const CreateUser = lazy(() => import("./pages/UserMangaement/CreateUser"));
const TicketQuickSearch = lazy(() => import('./pages/TicketManagement/TicketQuickSearch/TicketQuickSearch'));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const CreateCompany = lazy(() => import('./pages/ComapnyManagement/CompanyCreate'));
const CompanyManagement = lazy(() => import('./pages/ComapnyManagement/CompanyManagement'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const CustomFields = lazy(() => import('./pages/Settings/CustomFields/CustomFields'));
const AssetManagement = lazy(() => import('./pages/AssetManagement/AssetManagement'));
const CreateOrUpdateAssetView = lazy(() => import('./pages/AssetManagement/CreateOrUpdateAssetView'));
const CreateFrachise = lazy(() => import('./pages/FranchiseMangement/CreateFrachise'));
const ListFranchise = lazy(() => import('./pages/FranchiseMangement/ListFranchise'));
const ListExportAssets = lazy(() => import('./pages/ExportAssetMangement/ListExportAssets'));
const ItAmcManagement = lazy(() => import('./pages/ItAmcManagement/ItAmcManagement'));
const Register = lazy(() => import('./pages/Register'));
const EnquiryManagement = lazy(() => import('./pages/EnquirySupport/EnquiryManagement'));
const ListInvoice = lazy(() => import('./pages/RenewalManagement/ListInvoice'));


function NoPermission() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh" textAlign="center">
            <BlockIcon style={{ fontSize: 120, color: '#f44336' }} />
            <Typography variant="h4" gutterBottom>
                Access Denied
            </Typography>
            <Typography variant="subtitle1">
                Sorry, you do not have the necessary permissions to view this page or the requested page does not exist.
            </Typography>
        </Box>
    );
}

function Home() {
    return (
        <div>
            <h1>Dashboard - Work In Progress</h1>
        </div>
    );
}

const App = () => {
    const muiTheme = muiUseTheme();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const sessionUserRole = useSelector(state => state?.userData?.data?.role);
    const sessionUser = useSelector(state => state?.userData?.data);
    const quickSearchModalOpen = useSelector(state => state.ticketManagement.ticketQuickSearch.isModalOpen);

    const handleKeyDown = useCallback((event) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'K') {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'isModalOpen',
                    value: !quickSearchModalOpen,
                },
            });
        }
    }, [dispatch, quickSearchModalOpen]);

    useEffect(() => {
        if (['superAdmin', 'technicalAdmin', 'coreAdmin'].includes(sessionUserRole)) {
            document.addEventListener('keydown', handleKeyDown);
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [sessionUserRole, handleKeyDown]);

    useEffect(() => {
        if (sessionUser?.franchise?.area) {
            const fetchSuperAdmin = async () => {
                try {
                    const respo = await superAdminCompanyExist();
                    if (respo.data) {
                        dispatch(updateCurrentFranchise(respo.data));
                    }
                } catch (error) {
                    console.error('Error fetching super admin status', error);
                }
            };
            fetchSuperAdmin();
        }
    }, [sessionUser.franchise, dispatch]);

    const renderRoutes = () => (
        <Routes>
            <Route element={
                <PrivateRoutes />
            }>
                <Route
                    element={['coreAdmin', 'superAdmin', 'technicalAdmin', 'customerAdmin', 'customerUser', 'supportAgent'].includes(sessionUserRole) ? <Suspense fallback={<Loading />}> <Dashboard /> </Suspense> : <Home />}
                    path="/"
                />
                <Route
                    element={isMobile
                        ? <div>This page is not compatible with mobile view</div>
                        : (['coreAdmin', 'superAdmin', 'supportAgent'].includes(sessionUserRole) ?  <Suspense fallback={<Loading />}><AdminChatComponent /> </Suspense> : <NoPermission />)
                    }
                    path="/Support"
                />
                <Route
                    element={['coreAdmin', 'superAdmin', 'customerAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(sessionUserRole) ?  <Suspense fallback={<Loading />}><UserManagement /> </Suspense> : <NoPermission />}
                    path="/User-Management"
                />
                <Route element={ <Suspense fallback={<Loading />}><TicketsManagement /> </Suspense>} path="/tickets" />
                <Route element={ <Suspense fallback={<Loading />}><TicketsManagement /> </Suspense>} path="/tickets/:id" />
                <Route element={ <Suspense fallback={<Loading />}><CompanyManagement /> </Suspense>} path="/Company-Management" />
                <Route element={ <Suspense fallback={<Loading />}><ListInvoice /> </Suspense>} path="/Renewals-Management" />
                <Route element={sessionUser.userType === 'Admin' ?  <Suspense fallback={<Loading />}><AssetManagement /> </Suspense> : <NoPermission />} path="/Asset-Management" />
                <Route element={sessionUser.userType === 'Admin' ?  <Suspense fallback={<Loading />}><AssetManagement /> </Suspense> : <NoPermission />} path="/Asset-Management/:id" />
                <Route element={['superAdmin', 'coreAdmin'].includes(sessionUserRole) ? <Suspense fallback={<Loading />}> <Settings />  </Suspense>: <NoPermission />} path="/Settings" />
                <Route element={['superAdmin', 'coreAdmin'].includes(sessionUserRole) ?  <Suspense fallback={<Loading />}><CustomFields /> </Suspense> : <NoPermission />} path="/Settings/CustomFields" />
                <Route element={['coreAdmin'].includes(sessionUserRole) ?  <Suspense fallback={<Loading />}><ListFranchise /> </Suspense> : <NoPermission />} path="/franchise" />
                <Route element={sessionUser.isEditExportAsset ?  <Suspense fallback={<Loading />}><ListExportAssets /> </Suspense> : <NoPermission />} path="/export-assets-management" />
                <Route element={sessionUser.userType === "Admin" ?  <Suspense fallback={<Loading />}><ItAmcManagement /> </Suspense> : <NoPermission />} path="/it-amc-Management" />
                <Route element={sessionUser.userType === "Admin" ? <Suspense fallback={<Loading />}> <EnquiryManagement /> </Suspense> : <NoPermission />} path="/enquiry-management" />
            </Route>
            <Route element={ <Suspense fallback={<Loading />}><Register /> </Suspense>} path="/register" />
            <Route element={ <Suspense fallback={<Loading />}><CustomerLogin /> </Suspense>} path="/login" />
        </Routes>
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <Router>
                    {renderRoutes()}
                </Router>
                {localStorage.getItem('isSessionOut') !== 'true' && (
                    <Suspense fallback={<Loading />}>
                        <CreateUser />
                        <TicketQuickSearch />
                        <CreateCompany />
                        <CreateOrUpdateAssetView />
                        <CreateFrachise />
                    </Suspense>
                )}
            </LocalizationProvider>
            <ToastContainer />
        </ThemeProvider>
    );
};

export default App;
