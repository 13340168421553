import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTicket,
  getSavedTicket,
  getAiSuggestionsForTicketHeading,
} from "../../app/Ticket/TicketManagement";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  Skeleton,
  Chip,
} from "@mui/material";
import TicketModalSkeleton from "./TicketModalSkeleton";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ChatTwoToneIcon from "@mui/icons-material/ChatTwoTone";
import "../../index.css";
import { CreateButton } from "../../components/commons/Buttons";
import "./ticket.css";
import SelectInput from "../../components/commons/SelectInput";
import MUIDatePicker from "../../components/commons/DatePicker/DatePicker";
import {
  getCompaniesList,
  getCompanyLocations,
} from "../UserMangaement/Service";
import Autocomplete from "../../components/commons/Autocomplete";
import FileUploader from "../../components/FileUploader/FileUploader";
import TicketStatusSelector from "../../components/commons/TicketStatusSelector";
import LabelSelector from "../../components/commons/LabelSelector";
import MultipleUserSelector from "../../components/commons/MultipleUserSelector/MultipleUserSelector";
import TicketPrioritySelector from "../../components/commons/TicketPrioritySelector";
import ProposalDataGrd from "./ProposalDataGrid";
import AssetController from "../../components/commons/AssetController/AssetController";
import { themeBackgroundPrimaryColor } from "../../utilities/colorTheme";

const NewTicketView = () => {
  const sessionUser = useSelector((state) => state.userData.data);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const newTicketValues = useSelector(
    (state) => state.ticketManagement.newTicket
  );
  const savedTicketId = useSelector(
    (state) => state.ticketManagement.existingTicket.customTicketId
  );
  const [aiSuggestAnchorE1, setAiSuggestAnchorE1] = useState(null);
  const [aiSuggestDrawerOpen, setAiSuggestDrawerOpen] = useState(false);
  const ticketHeadRef = useRef(null);
  const [suggestDialogueWidth, setSuggestDialogueWidth] = useState("100px");
  const aiSuggestionManagement = useSelector(
    (state) => state.ticketManagement.aiSuggestionManagement
  );
  const [companyLocationsList, setCompanyLocationsList] = useState([]);

  const dialogContentStyle = {
    // overflow: 'hidden',
    maxHeight: isMobile ? "100vh" : "75vh",
    overflowY: "auto",
  };
  const [companiesList, setCompaniesList] = useState([]);
  const [proposalBreakUps, setProposalBreakUps] = useState([]);

  useEffect(() => {
    getCompaniesList().then((result) => {
      if (result?.data?.length) {
        let tempArr = [];
        result.data.forEach((item) => {
          tempArr.push({ value: item._id, label: item.companyName });
        });
        setCompaniesList(tempArr);
      } else if (result.data) {
        setCompaniesList([
          { value: result.data._id, label: result.data.companyName },
        ]);
        dispatch({
          type: "ticketManagement/updateFieldValues",
          payload: {
            state: "newTicket",
            field: "company",
            value: {
              value: sessionUser.company._id,
              label: sessionUser.company.companyName,
            },
          },
        });
      }
    });

    // If proposal break values are there in the redux state then sync that data with local state
    if (
      newTicketValues?.proposalBreakUps?.length &&
      proposalBreakUps.length === 0
    ) {
      setProposalBreakUps([...newTicketValues?.proposalBreakUps]);
    }
  }, []);

  function saveUploadedFilesInRedux(fileData) {
    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: {
        state: "newTicket",
        field: "uploads",
        value: [...newTicketValues.uploads, fileData],
      },
    });
  }

  function removeUploadedFileFromRedux(fileName) {
    if (fileName) {
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "newTicket",
          field: "uploads",
          value: newTicketValues.uploads?.filter(
            (item) => item.filename !== fileName
          ),
        },
      });
    }
  }

  const handleProposalBreakUpsGridDataChange = (newValue) => {
    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: {
        state: "newTicket",
        field: "proposalBreakUps",
        value: newValue,
      },
    });
    setProposalBreakUps(newValue);
  };

  /**
   * Dispatch create ticket async thunk function to redux
   */
  const handleTicketCreateSubmission = async () => {
    dispatch(createTicket(newTicketValues));
  };

  /**
   * Handle API Request state - Error Handling
   */
  useEffect(() => {
    if (
      newTicketValues.isErrorInTicketCreate &&
      newTicketValues?.validationErrors
    ) {
      const errorMessages = Object.values(newTicketValues.validationErrors);

      // Format error messages as a list
      const formattedErrors = (
        <ul>
          {errorMessages.map((errorMessage, index) => (
            <li key={index}>{errorMessage}</li>
          ))}
        </ul>
      );

      toast.error(formattedErrors, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: false,
        draggable: true,
        style: { width: "600px" },
      });
    }
  }, [newTicketValues?.validationErrors]);

  /**
   * Handle API Request state - Information Message
   */
  useEffect(() => {
    if (newTicketValues?.errorMessage !== "") {
      toast.error(newTicketValues?.errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        style: { width: "400px" },
      });
    }
  }, [newTicketValues?.errorMessage]);

  /**
   * Handle API Request state - Success Message
   */
  useEffect(() => {
    if (newTicketValues?.isSuccessInTicketCreate) {
      toast.success("Ticket has been generated successfully.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        style: { width: "400px" },
      });
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "newTicket",
          field: "isSuccessInTicketCreate",
          value: false,
        },
      });
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "mainState",
          field: "existingTicketModalOpen",
          value: true,
        },
      });
      dispatch(getSavedTicket(savedTicketId));
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "newTicket",
          field: "isLoading",
          value: false,
        },
      });
      dispatch({
        type: "ticketManagement/resetState",
        payload: { field: "newTicket" },
      });
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "mainState",
          field: "newTicketModalOpen",
          value: false,
        },
      });
    }
  }, [newTicketValues?.isSuccessInTicketCreate]);

  const handleAiSuggestDrawerOpen = () => {
    if (
      newTicketValues.ticketHead !== aiSuggestionManagement.lastRequestQuery
    ) {
      dispatch(
        getAiSuggestionsForTicketHeading({
          query: newTicketValues.ticketHead,
          isUniqueSuggestionNeeded: false,
        })
      );
    }
    const ticketHeadWidth = ticketHeadRef.current.offsetWidth;

    setSuggestDialogueWidth(`${ticketHeadWidth}px`);
    setAiSuggestAnchorE1(ticketHeadRef.current);
    setAiSuggestDrawerOpen(true);
  };

  const handleAiSuggestDrawerClose = () => {
    setAiSuggestAnchorE1(null);
    setAiSuggestDrawerOpen(false);
  };

  // Display error message when ai suggestion api throws any errors
  useEffect(() => {
    if (aiSuggestionManagement.errorMessage !== null) {
      toast.error(aiSuggestionManagement?.errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        style: { width: "400px" },
      });
      dispatch({
        type: "ticketManagement/updateFieldValues",
        payload: {
          state: "aiSuggestionManagement",
          field: "errorMessage",
          value: null,
        },
      });
      handleAiSuggestDrawerClose();
    }
  }, [aiSuggestionManagement.errorMessage]);

  const selectSuggestion = (string) => {
    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: {
        state: "newTicket",
        field: "ticketHead",
        value: string,
      },
    });
  };

  useEffect(() => {
    if (newTicketValues?.company?.value) {
      getSelectedCompaniesLocations();
    }

    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: { state: "newTicket", field: "companyLocation", value: null },
    });
    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: { state: "newTicket", field: "reportedUsers", value: [] },
    });
    dispatch({
      type: "ticketManagement/updateFieldValues",
      payload: { state: "newTicket", field: "assignedUsers", value: [] },
    });
  }, [newTicketValues?.company?.value]);

  const getSelectedCompaniesLocations = async () => {
    try {
      const locationsList = await getCompanyLocations(
        newTicketValues.company?.value
      );
      setCompanyLocationsList(
        locationsList?.data?.map((item) => ({
          value: item.id,
          label: item.locationName,
        })) || []
      );
    } catch (error) {
      setCompanyLocationsList([]);
      console.error(`Failed to get locations: ${error.message}`);
    }
  };

  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      fullWidth
      maxWidth={isMobile ? "md" : "lg"}
    >
      <div className="backdrop" />
      <div className="dialog-wrapper">
        <Paper className="dialog-paper">
          <DialogTitle
            className={isMobile ? "fixed-header" : ""}
            sx={{
              backgroundColor: themeBackgroundPrimaryColor,
              color: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: 0,
            }}
          >
            <strong>Create Ticket</strong>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                dispatch({
                  type: "ticketManagement/handleTicketModals",
                  payload: { modalType: "newTicketModalOpen" },
                });
              }}
              aria-label="close"
              sx={{
                transition: "background-color 0.3s, color 0.3s",
                "&:hover": {
                  color: "grey",
                  backgroundColor: "#fff",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {/* <Divider /> */}
          <DialogContent
            className="dialog-content custom-scrollbar"
            style={dialogContentStyle}
          >
            <div>
              <Grid container spacing={2}>
                {!newTicketValues?.isLoading && (
                  <>
                    {/* TICKET HEADING */}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={
                            newTicketValues?.ticketHead?.length > 10 ? 11.5 : 12
                          }
                        >
                          <InputLabel
                            sx={{ marginTop: "10px" }}
                            htmlFor="ticketHead"
                          >
                            Ticket Heading
                          </InputLabel>
                          <div
                            className={
                              !newTicketValues.ticketHead ||
                              newTicketValues.ticketHead === ""
                                ? "ticket-head-div ticket-head-no-input-error"
                                : "ticket-head-div"
                            }
                            name="ticketHead"
                            contentEditable
                            aria-required
                            required={true}
                            ref={ticketHeadRef}
                            onFocus={(e) => {
                              const div = e.target;

                              // Create a range and set it to the end of the content
                              const range = document.createRange();
                              range.selectNodeContents(div);
                              range.collapse(false); // Set the cursor at the end

                              // Select the range to set the cursor position
                              const selection = window.getSelection();
                              selection.removeAllRanges();
                              selection.addRange(range);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                document.execCommand(
                                  "insertHTML",
                                  false,
                                  "<br>"
                                );
                                e.target.blur(); // Blur the contentEditable div
                              }
                            }}
                            onBlur={(e) => {
                              const div = e.target;
                              const text = div.textContent;

                              // Update your state or dispatch action here when the input is blurred
                              dispatch({
                                type: "ticketManagement/updateFieldValues",
                                payload: {
                                  state: "newTicket",
                                  field: "ticketHead",
                                  value: text,
                                },
                              });
                            }}
                          >
                            {newTicketValues.ticketHead}
                          </div>
                        </Grid>

                        {/* Re-write ticket heading with AI Suggestion */}
                        {newTicketValues?.ticketHead?.length > 10 && (
                          <Grid
                            item
                            xs={0.5}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Re-write with AI Model">
                              <IconButton sx={{ p: 1, marginTop: "30px" }}>
                                <ChatTwoToneIcon
                                  color="primary"
                                  sx={{ fontSize: 40 }}
                                  onClick={handleAiSuggestDrawerOpen}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* TICKET DESCRIPTION */}
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                      <InputLabel htmlFor="ticketDescription">
                        Ticket Description
                      </InputLabel>
                      <div
                        className="ticket-description-div"
                        name="ticketHead"
                        contentEditable
                        aria-required
                        required={true}
                        onFocus={(e) => {
                          const div = e.target;

                          // Create a range and set it to the end of the content
                          const range = document.createRange();
                          range.selectNodeContents(div);
                          range.collapse(false); // Set the cursor at the end

                          // Select the range to set the cursor position
                          const selection = window.getSelection();
                          selection.removeAllRanges();
                          selection.addRange(range);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            document.execCommand("insertHTML", false, "<br>");
                            e.target.blur(); // Blur the contentEditable div
                          }
                        }}
                        onBlur={(e) => {
                          const div = e.target;
                          const text = div.textContent;

                          // Update your state or dispatch action here when the input is blurred
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "ticketDescription",
                              value: text,
                            },
                          });
                        }}
                      >
                        {newTicketValues.ticketDescription}
                      </div>
                    </Grid>

                    {/* COMPANY */}
                    <Grid item xs={isMobile ? 12 : 6}>
                      <Autocomplete
                        label="Company"
                        name="company"
                        value={newTicketValues.company}
                        onChange={(e) => {
                          if (sessionUser.userType === "Admin") {
                            dispatch({
                              type: "ticketManagement/updateFieldValues",
                              payload: {
                                state: "newTicket",
                                field: "company",
                                value: e.target.value,
                              },
                            });
                          }
                        }}
                        options={companiesList}
                        required={true}
                        error={!newTicketValues.company}
                        // disabled={sessionUser.role !== 'superAdmin'}
                      />
                    </Grid>

                    {/* COMPANY LOCATION */}
                    <Grid item xs={isMobile ? 12 : 6}>
                      <Autocomplete
                        label="Company Location"
                        name="companyLocation"
                        value={newTicketValues.companyLocation}
                        onChange={(e) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "companyLocation",
                              value: e.target.value,
                            },
                          });
                        }}
                        options={companyLocationsList}
                        required={false}
                        // error={(!data.company && !data.userType === 'Admin')}
                      />
                    </Grid>

                    {/* TICKET STATUS */}
                    {(sessionUser.role === "superAdmin" ||
                      sessionUser.role === "technicalAdmin" ||
                      sessionUser.role === "coreAdmin") && (
                      <Grid item xs={isMobile ? 12 : 6}>
                        <TicketStatusSelector
                          name={"status"}
                          label="Status"
                          value={newTicketValues.status}
                          disabled={sessionUser.userType !== "Admin"}
                          onChange={(e) => {
                            dispatch({
                              type: "ticketManagement/updateFieldValues",
                              payload: {
                                state: "newTicket",
                                field: "status",
                                value: e.target.value,
                              },
                            });
                          }}
                          ticketState={"new"}
                        />
                      </Grid>
                    )}

                    {/* APPROVAL REASON TEXT INPUT */}
                    {(newTicketValues.status === "reqCustomerAdminApproval" ||
                      newTicketValues.status === "reqSuperAdminApproval") && (
                      <Grid item xs={12}>
                        <TextField
                          id="admins-approval-reason"
                          label="Approval Reason"
                          fullWidth
                          multiline
                          minRows={2}
                          maxRows={4}
                          required
                          value={newTicketValues.approvalReason}
                          onChange={(e) => {
                            dispatch({
                              type: "ticketManagement/updateFieldValues",
                              payload: {
                                state: "newTicket",
                                field: "approvalReason",
                                value: e.target.value,
                              },
                            });
                          }}
                          error={
                            (newTicketValues.status ===
                              "reqCustomerAdminApproval" ||
                              newTicketValues.status ===
                                "reqSuperAdminApproval") &&
                            !newTicketValues.approvalReason
                          }
                        />
                      </Grid>
                    )}

                    <Grid item xs={isMobile ? 12 : 6}>
                      <LabelSelector
                        value={newTicketValues.labels}
                        onChange={(newValue) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "labels",
                              value: newValue,
                            },
                          });
                        }}
                      />
                    </Grid>

                    {/* DUE DATE */}
                    <Grid
                      item
                      xs={isMobile ? 12 : 6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <MUIDatePicker
                        label="Due Date"
                        value={
                          isNaN(Date.parse(newTicketValues.dueDate))
                            ? null
                            : new Date(newTicketValues.dueDate)
                        }
                        onChange={(e) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "dueDate",
                              value: e,
                            },
                          });
                        }}
                        onClear={() => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "dueDate",
                              value: null,
                            },
                          });
                        }}
                        minDate={Date.now()}
                        timeZone={
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                        }
                      />
                    </Grid>

                    {/* TICKET PRIORITY */}
                    <Grid item xs={isMobile ? 12 : 6}>
                      <TicketPrioritySelector
                        name={"priority"}
                        required={true}
                        label="Priority"
                        value={newTicketValues.priority}
                        onChange={(e) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "priority",
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Grid>

                    {/* PAYMENT STATUS */}
                    {(sessionUser.role !== "superAdmin" ||
                      sessionUser.role !== "technicalAdmin" ||
                      sessionUser.role !== "coreAdmin") && (
                      <>
                        <Grid item xs={isMobile ? 12 : 6}>
                          <SelectInput
                            label={"Payment Status"}
                            value={newTicketValues.paymentStatus}
                            required
                            default={"notRequired"}
                            onChange={(e) => {
                              dispatch({
                                type: "ticketManagement/updateFieldValues",
                                payload: {
                                  state: "newTicket",
                                  field: "paymentStatus",
                                  value: e.target.value,
                                },
                              });
                            }}
                            options={[
                              { key: "required", name: "Payment Required" },
                              {
                                key: "notRequired",
                                name: "Payment Not Required",
                              },
                              { key: "paid", name: "Paid" },
                              {
                                key: "paymentPending",
                                name: "Payment Pending",
                              },
                            ]}
                          />
                        </Grid>
                      </>
                    )}

                    {/* Back Dated Ticket */}
                    {(sessionUser.role === "superAdmin" ||
                      sessionUser.role === "technicalAdmin" ||
                      sessionUser.role === "coreAdmin") && (
                      <>
                        <Grid item xs={isMobile ? 12 : 6}>
                          <MUIDatePicker
                            label="Back dated Created date"
                            value={
                              isNaN(
                                Date.parse(newTicketValues.backDatedCreateDate)
                              )
                                ? null
                                : new Date(newTicketValues.backDatedCreateDate)
                            }
                            onChange={(e) => {
                              const dateString = e.toLocaleDateString("en-GB"); // Convert to 'dd/mm/yyyy' format
                              dispatch({
                                type: "ticketManagement/updateFieldValues",
                                payload: {
                                  state: "newTicket",
                                  field: "backDatedCreateDate",
                                  value: dateString,
                                },
                              });
                            }}
                            onClear={() => {
                              dispatch({
                                type: "ticketManagement/updateFieldValues",
                                payload: {
                                  state: "newTicket",
                                  field: "backDatedCreateDate",
                                  value: null,
                                },
                              });
                            }}
                            maxDate={new Date(Date.now() - 86400000)} // this is yesterday
                            timeZone={
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            } // Set timezone dynamically
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={isMobile ? 12 : 6}>
                      <AssetController
                        onChange={(newValue) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "assets",
                              value: newValue,
                            },
                          });
                        }}
                        selectedAssets={newTicketValues.assets}
                        company={newTicketValues?.company?.value}
                        location={newTicketValues?.companyLocation?.value || ""}
                      />
                    </Grid>
                    {newTicketValues.assets.length > 0 && (
                      <Grid
                        xs={12}
                        container
                        spacing={1}
                        sx={{ marginTop: "10px", padding: "2rem" }}
                      >
                        {newTicketValues.assets?.map((asset) => (
                          <Grid item key={asset.value}>
                            <Chip
                              label={`${asset.label} - ${asset.contractType}`}
                              sx={{
                                borderRadius: "8px",
                                marginRight: "2px",
                                fontWeight: 500,
                                fontSize: "1.1rem",
                                textTransform: "uppercase",
                              }}
                              size={isMobile ? "small" : "medium"}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    {/* ASSIGNED USERS */}
                    <Grid item xs={12}>
                      <MultipleUserSelector
                        label="Assigned Technicians"
                        value={newTicketValues.assignedUsers}
                        disabled={
                          sessionUser.role !== "superAdmin" &&
                          sessionUser.role !== "technicalAdmin" &&
                          sessionUser.role !== "technician" &&
                          sessionUser.role !== "coreAdmin" &&
                          sessionUser.role !== "supportAgent"
                        }
                        error={!newTicketValues.assignedUsers?.length}
                        companyId={newTicketValues.company?.value}
                        onChange={(newValue) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "assignedUsers",
                              value: newValue,
                            },
                          });
                        }}
                      />
                    </Grid>

                    {/* REPORTED USERS */}
                    <Grid item xs={12}>
                      <MultipleUserSelector
                        label={"Reported Users"}
                        value={newTicketValues.reportedUsers}
                        disabled={
                          sessionUser.role !== "superAdmin" &&
                          sessionUser.role !== "technicalAdmin" &&
                          sessionUser.role !== "technician" &&
                          sessionUser.role !== "coreAdmin" &&
                          sessionUser.role !== "supportAgent"
                        }
                        required={true}
                        error={!newTicketValues.reportedUsers?.length}
                        companyId={newTicketValues.company?.value}
                        onChange={(newValue) => {
                          dispatch({
                            type: "ticketManagement/updateFieldValues",
                            payload: {
                              state: "newTicket",
                              field: "reportedUsers",
                              value: newValue,
                            },
                          });
                        }}
                      />
                    </Grid>

                    {/* FILE UPLOADS || ATTACHMENTS */}
                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FileUploader
                        onFileUploadSuccess={saveUploadedFilesInRedux}
                        onDeleteFile={removeUploadedFileFromRedux}
                        destinationParentFolder="tempFiles"
                        destinationSubFolder="tickets"
                        savedFiles={newTicketValues.uploads}
                        disabled={false}
                      />
                    </Grid>
                  </>
                )}

                {newTicketValues.isLoading && <TicketModalSkeleton />}
              </Grid>
            </div>

            {/* AI AUTOSUGGEST ELEMENTS AND LOGICS STARTS HERE */}
            <>
              <Menu
                sx={{ marginTop: "5px" }}
                anchorEl={aiSuggestAnchorE1}
                open={aiSuggestDrawerOpen}
                onClose={handleAiSuggestDrawerClose}
              >
                <Paper
                  elevation={0}
                  sx={{
                    minWidth: suggestDialogueWidth,
                    maxWidth: suggestDialogueWidth,
                    padding: "7px",
                  }}
                >
                  {!aiSuggestionManagement.isLoading &&
                  aiSuggestionManagement.aiSuggestedTicketHeadings.length ? (
                    <>
                      {aiSuggestionManagement.aiSuggestedTicketHeadings.map(
                        (suggestion, index) => {
                          return (
                            <MenuItem
                              key={`${index + suggestion[0]}`}
                              sx={{ fontSize: "1.3rem" }}
                              onClick={() => {
                                selectSuggestion(suggestion);
                              }}
                            >
                              {suggestion}
                            </MenuItem>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      <Skeleton
                        variant="rectangular"
                        height={48}
                        sx={{ marginBottom: "5px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        height={48}
                        sx={{ marginBottom: "5px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        height={48}
                        sx={{ marginBottom: "5px" }}
                      />
                      <Skeleton variant="rectangular" height={48} />
                    </>
                  )}
                </Paper>
              </Menu>
            </>
            {/* AI AUTOSUGGEST ELEMENTS AND LOGICS STARTS HERE */}
          </DialogContent>
          <Divider />
          <DialogActions
            className={isMobile ? "fixed-footer" : ""}
            sx={{
              backgroundColor: "#f4f4f4",
              position: "sticky",
              bottom: 0,
            }}
          >
            <CreateButton onClick={handleTicketCreateSubmission} />
          </DialogActions>
        </Paper>
      </div>
    </Dialog>
  );
};

export default NewTicketView;
