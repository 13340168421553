import React from 'react';
import { CircularProgress, Box, Typography, Fade } from '@mui/material';

const Loading = () => {
  return (
    <Fade in={true} timeout={800}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          aspectRatio: 2,
          maxHeight: "70vh",
          width: '100%',
          padding: '20px',
        }}
      >
        <CircularProgress size={60} thickness={5} />
        <Typography variant="h6" sx={{ marginTop: "16px", animation: 'fadein 2s' }}>
          Loading, please wait...
        </Typography>
      </Box>
    </Fade>
  );
}

export default Loading;
